import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ContactForm from '../components/Contact/ContactForm'

const ServiceTemplate = (props) => {
    let data = props.data.wpgraphql;

    console.log(data);

    function constructMetaData(page, currentPage, language) {
        let payload = {
            noIndex: page.seo.metaRobotsNoindex,
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu} lang={props.pageContext.language} />
            <PageBanner
                pageTitle={data.page.title}
                homePageText={props.pageContext.language === "EN" ? "Home" : (props.pageContext.language === "FR" ? "Accueil" : "Αρχική")}
                homePageUrl={props.pageContext.language === "EN" ? "/en/" : (props.pageContext.language === "FR" ? "/fr/" : "/")}
                activePageText={data.page.breadcrumbAcf.breadcrumbName}
            />

            <div className="services-details-desc container" style={{ paddingBottom: "4rem", paddingTop: "2rem" }} dangerouslySetInnerHTML={{ __html: data.page.content }}></div>

            {data.page.uri.includes("espa-pe") && (
                <div id="contact-form-s" style={{ paddingTop: "3rem" }}>
                    <ContactForm
                        data={data?.template?.contactInformationTemplate}
                        serviceData={data?.digitalService?.servicesAcf.cgroup}
                        staticForm={true}
                    />
                </div>
            )}
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    );
}

export default ServiceTemplate

export const pageQuery = graphql`
    query GET_GENERAL_PAGE($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum, $footerTemplateUri: ID!) {
        wpgraphql {
            page(id: $id){
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                    metaRobotsNoindex
                }
                breadcrumbAcf{
                    breadcrumbName
                }
                title
                content
                uri
            }
            services (first: 200, where: {language: $language , orderby: {field: MENU_ORDER, order: ASC}}) {
                edges {
                    node {
                        id
                        uri
                        title
                        slug
                    }
                }
            }

            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }

            template(id: "el-contact-information", idType: SLUG) {
                contactInformationTemplate {
                    form {
                        content
                        title
                        subTitle
                        content
                        fullName
                        email{
                            label
                            errorMessage
                        }
                        subject
                        message{
                            label
                            errorMessage
                        }
                        telephone{
                            label
                            errorMessage
                        }
                        sendButton
                    }
                    responseMessages{
                        success
                        failed
                    }
                    image {
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp {
                                fluid(maxWidth: 585, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                }
            }

        }
    }
`